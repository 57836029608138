// Library methods
import { useEffect, useRef } from "react";
import { BarCanvas, ResponsiveBarCanvas } from "@nivo/bar";

// Utilities
import { defaultGazeChartSpect } from "../../../utils/examReportsHelper";

const GazeGraph = ({
  data,
  chartSpecs = defaultGazeChartSpect,
  isPrint,
  containerHeight = 100,
  containerWidth = 700,
  chartWidth = 695,
  chartHeight = 65,
  isFirstSection,
}) => {
  const canvasContainerRef = useRef(null);
  useEffect(() => {
    HTMLCanvasElement.prototype.getBBox = function () {
      return { width: this.offsetWidth, height: this.offsetHeight };
    };
  }, []);

  useEffect(() => {
    if (!isFirstSection) return;
    const canvas = canvasContainerRef.current?.querySelector("canvas");
    if (canvas) {
      canvas.style.maxWidth = "100%";
    }
  }, [data, isFirstSection]);

  const chartKeys = [
    {
      name: "bottomDistance",
      color: "white",
    },
    {
      name: "gazeValue",
      color: "black",
    },
  ];

  return !isPrint ? (
    <div
      ref={canvasContainerRef}
      style={{
        height: containerHeight,
        borderTop: "2px solid #000",
        borderBottom: "2px solid #000",
      }}
    >
      <ResponsiveBarCanvas
        border
        data={data}
        keys={chartKeys.map((key) => key.name)}
        indexBy="id"
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        padding={0}
        maxValue={chartSpecs?.totalValues}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: false }}
        colors={chartKeys.map((key) => key.color)}
        borderWidth={0.35}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={null}
        enableGridY={false}
        enableLabel={false}
        tooltip={() => null}
        legends={[]}
        role="application"
        ariaLabel="Gaze chart"
      />
    </div>
  ) : (
    <div
      style={{
        height: containerHeight,
        width: containerWidth,
        borderTop: "2px solid #000",
        borderBottom: "2px solid #000",
      }}
    >
      <BarCanvas
        height={chartHeight}
        width={chartWidth}
        border
        data={data}
        keys={chartKeys.map((key) => key.name)}
        indexBy="id"
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        padding={0}
        maxValue={chartSpecs.totalValues}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: false }}
        colors={chartKeys.map((key) => key.color)}
        borderWidth={0.35}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={null}
        enableGridY={false}
        enableLabel={false}
        tooltip={() => null}
        legends={[]}
        role="application"
        ariaLabel="Gaze chart"
      />
    </div>
  );
};

export default GazeGraph;
