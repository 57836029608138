// Library methods
import { forwardRef, useCallback, useContext } from "react";

// Styles
import styles from "./index.module.css";

// Components
import Loader from "../../../../../components/UI/Loader";

// Utilities
import {
  calculateXPaddingNumberGraph,
  calculateYPaddingNumberGraph,
  GridTypes,
} from "../../../../../utils/examReportsHelper";
import { ReportEachSectionContext } from "../../../../../contexts/CommentContext";
import { EyeContext } from "../../../../../contexts/ExamPropertyContext";
import ChartCross from "../../../../../components/UI/ChartCross";

const NumericalGraph = ({ processedPoints, gridType, isTotal = true }, ref) => {
  const { eyeReport } = useContext(ReportEachSectionContext);
  const { eyeRight, eyeLeft } = useContext(EyeContext);

  const shouldIgnoreValue = useCallback(
    (eye, spot) => {
      return (
        gridType !== "G_10_2" &&
        ((eye === "Left" && spot.x === -3 && spot.y === -1) ||
          (eye === "Left" && spot.x === -3 && spot.y === 1) ||
          (eye === "Right" && spot.x === 3 && spot.y === -1) ||
          (eye === "Right" && spot.x === 3 && spot.y === 1))
      );
    },
    [gridType]
  );
  const is24C = gridType === GridTypes.G24C;

  // Show loader while data is not yet processed
  if (processedPoints.length === 0) {
    return <Loader containerHeight="252px" />;
  }

  return (
    <main className={styles.container} ref={ref}>
      <ChartCross gridType={gridType} />
      {/* mapping the completed visual spots, if data is available */}
      {processedPoints.map((point, i) => {
        const spot = point?.Spot?.position;
        const ml = calculateXPaddingNumberGraph(spot.x);
        const mt = calculateYPaddingNumberGraph(spot.y);
        const shouldIgnore = shouldIgnoreValue(eyeReport, spot);
        // console.log(spot);
        return (
          <div
            key={i}
            style={{
              position: "absolute",
              paddingLeft: is24C
                ? 0 < spot.x && spot.x < 1
                  ? ml + 4
                  : !Number.isInteger(spot.x) && spot.y === -2
                    ? ml + 2
                    : eyeReport === eyeLeft && spot.x === -1 && spot.y === -2
                      ? ml - 1
                      : eyeReport === eyeRight && spot.x === 1 && spot.y === -2
                        ? ml + 2
                        : ml
                : ml,
              paddingTop: is24C
                ? !Number.isInteger(spot.x) && spot.y === -2
                  ? mt + 4
                  : ((eyeReport === eyeLeft && spot.x === -1) ||
                        (eyeReport === eyeRight && spot.x === 1)) &&
                      spot.y === -2
                    ? mt + 2
                    : spot.y === -1.33
                      ? mt + 3
                      : mt
                : mt,
            }}
          >
            {!shouldIgnore && (
              <p
                style={{
                  fontSize: is24C
                    ? !Number.isInteger(spot.x) && spot.y === -2
                      ? "9px"
                      : ((eyeReport === eyeLeft && spot.x === -1) ||
                            (eyeReport === eyeRight && spot.x === 1)) &&
                          spot.y === -2
                        ? "11px"
                        : !Number.isInteger(spot.x) || !Number.isInteger(spot.y)
                          ? "10px"
                          : "13px"
                    : "13px",
                  marginTop: "0px",
                }}
              >
                {Math.floor(
                  isTotal ? point?.TotalDeviation : point?.PatternDeviation
                )}
              </p>
            )}{" "}
          </div>
        );
      })}
    </main>
  );
};

export default forwardRef(NumericalGraph);
