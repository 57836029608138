// Library methods
import { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

// MUI Components
import { Grid } from "@mui/material";

// Components
import ReportPdf from "../../components/UI/Report";
import ColorVisionPdfReport from "../../containers/Report/ColorVisionReport/EyeReport";
import ContrastPdfReport from "../../containers/Report/ContrastSensitivityReport/EyeReport";
import EstermanPdfReport from "../../containers/Report/EstermanReport/EyeReport";
import VisualFieldPdfReport from "../../containers/Report/VisualFieldReport/EyeReport";
import VisualAcuityPdfReport from "../../containers/Report/VisualAcuityReport/EyeReport";
import Full120Report from "../../containers/Report/SingleThresholdReport/EyeReport";

// Utilities
import {
  ReportEachSectionContext,
  CommentAPInfoContext,
  CommentTitleProvider,
} from "../../contexts/CommentContext";
import { getExamType } from "../../utils/examReportsHelper";

// Contexts
import {
  AlgorithmContext,
  ExamTypeContext,
} from "../../contexts/ExamPropertyContext";
import { ReportDataContext } from "../../contexts/ReportDataContext";
import { ValidatePDFContext } from "../../contexts/ValidatePDFContext";
import { ErrorMessageComponent } from "../../components/UI/ErrorMessage";
import useClinic from "../../hooks/useClinic";
import { getClinics } from "../../services/Clinic";

const Pdf = () => {
  const { TYPE_VISUAL_ACUITY } = useContext(ExamTypeContext);
  const { esterman, pellirobson, tumbling, landoltC, snellen } =
    useContext(AlgorithmContext);
  const { setClinicLicensing } = useClinic();
  const [searchParams] = useSearchParams();
  const clinicId = searchParams.get("clinic_id") || "";
  const patientId = searchParams.get("patient_id") || "";
  const examId = searchParams.get("exam_id") || "";

  const forBackendPdf = true;
  const {
    isLoading,
    patientName,
    patient,
    exam,
    examReport1,
    examReport2,
    eyeReport1,
    eyeReport2,
    comments1,
    comments2,
    sectionId1,
    sectionId2,
    refetchComment,
    durationStartTime1,
    durationStartTime2,
    duration1,
    duration2,
    shouldDisplayReport,
    clinicTimezone,
  } = ReportPdf(forBackendPdf, clinicId, patientId, examId);

  const EachSectionWrapper = ({
    report,
    sectionId,
    childComponent,
    eyeReport,
    durationStartTime,
    duration,
    comments,
    needBreak = null,
  }) => {
    return (
      <Grid
        item
        xs={12}
        lg={6}
        sx={{
          pageBreakAfter: needBreak ? "always" : "auto",
        }}
      >
        <ReportEachSectionContext.Provider
          value={{
            sectionId: sectionId,
            examReport: report,
            eyeReport: eyeReport,
            durationStartTime: durationStartTime,
            duration: duration,
            comments: comments,
          }}
        >
          {childComponent}
        </ReportEachSectionContext.Provider>
      </Grid>
    );
  };
  const reportSection = () => {
    const type = getExamType(exam);
    const renderEachSectionWrapper = (type) => {
      const commonAttributes = {
        report1: examReport1,
        report2: examReport2,
        sectionId1: sectionId1,
        sectionId2: sectionId2,
        eyeReport1: eyeReport1,
        eyeReport2: eyeReport2,
        duration1: duration1,
        duration2: duration2,
        comments1: comments1,
        comments2: comments2,
      };

      let childComponent;
      let needBreak;

      switch (type) {
        case "D-15":
          childComponent = <ColorVisionPdfReport />;
          needBreak =
            exam?.colorVisionSections?.[0]?.completionDate &&
            exam?.colorVisionSections?.[1]?.completionDate;
          break;

        case pellirobson:
        case tumbling:
          childComponent = <ContrastPdfReport />;
          needBreak =
            exam?.contrastSensitivitySections?.[0]?.completionDate &&
            exam?.contrastSensitivitySections?.[1]?.completionDate;
          break;

        case TYPE_VISUAL_ACUITY:
        case landoltC:
        case snellen:
          childComponent = <VisualAcuityPdfReport />;
          needBreak =
            exam?.visualAcuitySections?.[0]?.completionDate &&
            exam?.visualAcuitySections?.[1]?.completionDate;
          break;

        default:
          return null;
      }

      return (
        <>
          <EachSectionWrapper
            report={commonAttributes.report1}
            sectionId={commonAttributes.sectionId1}
            childComponent={childComponent}
            eyeReport={commonAttributes.eyeReport1}
            duration={commonAttributes.duration1}
            comments={commonAttributes.comments1}
            needBreak={needBreak}
          />
          <EachSectionWrapper
            report={commonAttributes.report2}
            sectionId={commonAttributes.sectionId2}
            childComponent={childComponent}
            eyeReport={commonAttributes.eyeReport2}
            duration={commonAttributes.duration2}
            comments={commonAttributes.comments2}
          />
        </>
      );
    };

    switch (type) {
      case esterman:
        return (
          <EachSectionWrapper
            report={examReport1}
            sectionId={sectionId1}
            childComponent={<EstermanPdfReport />}
            comments={comments1}
          />
        );
      case "D-15":
      case pellirobson:
      case tumbling:
      case TYPE_VISUAL_ACUITY:
      case landoltC:
      case snellen:
        return renderEachSectionWrapper(type);
      case "Single Threshold":
        return (
          <>
            <EachSectionWrapper
              report={examReport1}
              sectionId={sectionId1}
              childComponent={<Full120Report />}
              eyeReport={eyeReport1}
              durationStartTime={durationStartTime1}
              duration={duration1}
              comments={comments1}
              needBreak={
                exam?.visualFieldSections?.[0]?.completionDate &&
                exam?.visualFieldSections?.[1]?.completionDate
              }
            />

            <EachSectionWrapper
              report={examReport2}
              sectionId={sectionId2}
              childComponent={<Full120Report />}
              eyeReport={eyeReport2}
              durationStartTime={durationStartTime2}
              duration={duration2}
              comments={comments2}
            />
          </>
        );
      default:
        return (
          <>
            <EachSectionWrapper
              report={examReport1}
              sectionId={sectionId1}
              childComponent={<VisualFieldPdfReport />}
              eyeReport={eyeReport1}
              durationStartTime={durationStartTime1}
              duration={duration1}
              comments={comments1}
              needBreak={
                exam?.visualFieldSections?.[0]?.completionDate &&
                exam?.visualFieldSections?.[1]?.completionDate
              }
            />
            <EachSectionWrapper
              report={examReport2}
              sectionId={sectionId2}
              childComponent={<VisualFieldPdfReport />}
              eyeReport={eyeReport2}
              durationStartTime={durationStartTime2}
              duration={duration2}
              comments={comments2}
            />
          </>
        );
    }
  };

  useEffect(() => {
    try {
      const getLicensing = async () => {
        const { licensing } = await getClinics(null, clinicId);
        if (licensing) setClinicLicensing(licensing);
        return licensing;
      };
      getLicensing();
    } catch (e) {}
  }, [clinicId, setClinicLicensing]);
  return (
    <CommentTitleProvider>
      <CommentAPInfoContext.Provider
        value={{
          clinicId: clinicId,
          patientId: patientId,
          examId: examId,
        }}
      >
        <ValidatePDFContext.Provider value={{ forBackendPdf: true }}>
          <ReportDataContext.Provider
            value={{
              isLoading: isLoading,
              patientName: patientName,
              patient: patient,
              exam: exam,
              examReport: examReport1,
              examReport2: examReport2,
              eyeReport: eyeReport1,
              eyeReport2: eyeReport2,
              comments: comments1,
              comments2: comments2,
              sectionId: sectionId1,
              sectionId2: sectionId2,
              refetchComment: refetchComment,
              durationStartTime: durationStartTime1,
              durationStartTime2: durationStartTime2,
              duration: duration1,
              duration2: duration2,
              clinicTimezone: clinicTimezone,
            }}
          >
            {!shouldDisplayReport ? (
              <ErrorMessageComponent />
            ) : (
              <Grid container sx={{ display: "flex" }} columnSpacing={4}>
                {reportSection()}
              </Grid>
            )}
          </ReportDataContext.Provider>
        </ValidatePDFContext.Provider>
      </CommentAPInfoContext.Provider>
    </CommentTitleProvider>
  );
};

export default Pdf;
