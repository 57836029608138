// Library methods
import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useContext,
} from "react";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlay,
  faPause,
  faTrash,
  faCircle,
  faClose,
  faArrowRotateRight,
  faForward,
  faVrCardboard,
  faListCheck,
} from "@fortawesome/free-solid-svg-icons";

// MUI Components
import Stepper from "react-stepper-horizontal";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

// Components
import NumberGraph from "./../NumberGraph";
import GazeGraph from "./../GazeGraph";
import EstermanGraph from "./../EstermanGraph";
import SingleThresholdGraph from "./../SingleThresholdGraph";
import TumblingE from "./../TumblingE";
import PelliRobson from "./../PelliRobson";
import Loader from "./../Loader";
import ColorVisionGraph from "../ColorVisionGraph";

// Styles
import { ModalPaperProps } from "../../../styles/muiStylesHelper";

// Asset
import calibration from "../../../assets/images/icons/calibration.png";
import blackout from "../../../assets/images/grayscale/blackLive.jpeg";
import leftControllerBlack from "../../../assets/images/controllers/left_black.svg";
import controllerAudioWave from "../../../assets/images/controllers/audio_wave.svg";
import controllerAudioWaveMuted from "../../../assets/images/controllers/audio_wave_muted.svg";

// Utilities
import {
  deviceCalibrateLobbyRequested,
  deviceDisableVisualFIeldControllerBeepRequest,
  deviceEnableVisualFIeldControllerBeepRequest,
  deviceExamTerminationRequest,
  deviceUpdateVolumeRequest,
  subscribeToDevice,
  unsubscribeFromDevice,
  updateDeviceStage,
} from "../../../services/SignalR";
import {
  getActiveStep,
  getDeviceStagesTimelineText,
  DeviceStagesTimeline,
} from "../../../utils/signalRHelper";
import {
  getChartSpots,
  getColorVisionTime,
  getGazeChartData,
  getExamType,
  getVisualField,
} from "../../../utils/examReportsHelper";

// Contexts
import {
  AlgorithmContext,
  ExamTypeContext,
  EyeContext,
} from "../../../contexts/ExamPropertyContext";
import LiveUpdateNavigationModal from "./LiveUpdateNavigationModal";
import { useNavigate } from "react-router";
import { getExamById, updateExam } from "../../../services/Exam";
import useClinic from "../../../hooks/useClinic";
import { Alert, Tooltip } from "@mui/material";
import { useLiveReportData } from "../../../hooks/useLiveReporData";
import { WebRtcComponent } from "../WebRtc";
import VisualAcuityGraph from "../VisualAcuityGraph";
import ChartLegendExceptVisualField from "../ChartLegendExceptVisualField";
import { useExamStatus } from "../../../hooks/useExamStatus";
import { LiveUpdateStatusContext } from "../../../contexts/LiveUpdateStatusContext";
import ProgressBar from "../ProgressBar";
import { VolumeUp } from "@mui/icons-material";

const LiveUpdateModal = ({
  connection,
  refetchDevices,
  refetchExams,
  isLoadingSignalR,
  devicesWithExams,
}) => {
  // auth
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  // translations
  const { t } = useTranslation();

  // navigation
  const navigate = useNavigate();

  const { getExamStatus } = useExamStatus();

  const { open, exam, deviceId, handleLiveUpdateModalClose } = useContext(
    LiveUpdateStatusContext
  );

  const { eyeLeft, eyeRight } = useContext(EyeContext);

  // clinic context
  const { clinicId, clinicLicensing } = useClinic();

  // modal fullScreen on small screens
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { typeVisualField, typeColorVision, TYPE_VISUAL_ACUITY } =
    useContext(ExamTypeContext);

  // states
  const [isVrMode, setIsVrMode] = useState(false);
  const [examReportLive, setExamReportLive] = useState(undefined);
  const [, setHasSubscribedToExam] = useState(false);

  // check if the exam is currently paused
  const [isPaused, setIsPaused] = useState(false);

  // check if the exam is currently in confirmation stage
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [isNotStarted, setIsNotStarted] = useState(false);
  const [isRightCompleted, setIsRightCompleted] = useState(false);
  const [isTerminated, setIsTerminated] = useState(false);
  const [isBeepEnabled, setIsBeepEnabled] = useState(true);
  const [percentage, setPercentage] = useState(0);
  const [shouldFetchExam, setShouldFetchExam] = useState(false);
  const [shouldDisplayNavigation, setShouldDisplayNavigation] = useState(false);
  const [latestExam, setLatestExam] = useState(null);
  const [shouldDisplayEyeTrackingError, setshouldDisplayEyeTrackingError] =
    useState(false);
  // WebRtc
  const [peerConnection, setPeerConnection] = useState(null);
  const [dataChannel, setDataChannel] = useState(null);
  const [imageSrc, setImageSrc] = useState(blackout);
  const [volume, setVolume] = useState(100);

  // context
  const { pellirobson, tumbling, esterman, landoltC, snellen } =
    useContext(AlgorithmContext);

  // handling live data
  const { falsePositivePercentage, falseNegative, fixationLossPercentage } =
    useLiveReportData(examReportLive);

  const examStatus = useMemo(
    () => getExamStatus(exam, devicesWithExams),
    [devicesWithExams, exam, getExamStatus]
  );

  const patientName = useMemo(
    () =>
      exam?.patient?.firstName && exam?.patient?.lastName
        ? `${exam.patient.firstName} ${exam.patient.lastName}`
        : "",
    [exam?.patient]
  );

  // tasks to execute when closing the modal
  const onCloseTasks = useCallback(
    (event, reason) => {
      // calling the onclose method
      handleLiveUpdateModalClose(event, reason);

      // resetting some states
      setIsVrMode(false);
      setExamReportLive(undefined);
      setPercentage(0);
      setIsPaused(false);
      setIsNotStarted(false);
      setIsConfirmation(false);
      setIsRightCompleted(false);
      setIsTerminated(false);
      setHasSubscribedToExam(false);
      setShouldDisplayNavigation(false);
      setShouldFetchExam(false);
      setLatestExam(null);
    },
    [handleLiveUpdateModalClose]
  );

  // non esterman visual field
  const examType = useMemo(() => {
    return getExamType(exam);
  }, [exam]);
  const isVisualField = useMemo(() => {
    return exam?.type === typeVisualField && examType !== esterman;
  }, [typeVisualField, esterman, examType, exam?.type]);

  // esterman specifically
  const isEsterman = useMemo(() => {
    return examType === esterman;
  }, [esterman, examType]);

  const isPelirobson = useMemo(() => {
    return examType === pellirobson;
  }, [examType, pellirobson]);

  const isTumbling = useMemo(() => {
    return examType === tumbling;
  }, [examType, tumbling]);

  const isVisualAcuity = useMemo(() => {
    return examType === TYPE_VISUAL_ACUITY;
  }, [TYPE_VISUAL_ACUITY, examType]);

  const isLandoltC = useMemo(() => {
    return examType === landoltC;
  }, [landoltC, examType]);

  const isSnellen = useMemo(() => {
    return examType === snellen;
  }, [snellen, examType]);

  const isSingleThreshold = useMemo(() => {
    return getExamType(exam) === "Single Threshold";
  }, [exam]);

  const isOnline = useMemo(
    () => examStatus?.device?.deviceStatus?.stage?.stageType,
    [examStatus?.device?.deviceStatus?.stage?.stageType]
  );

  // handle the update of a device's stage
  const handleUpdateDeviceStage = useCallback(
    async (newStage) => {
      try {
        const token = await getAccessTokenSilently();
        const response = await updateDeviceStage(
          token,
          deviceId,
          newStage,
          connection
        );
        const data = JSON.parse(response);
        if (data?.state === true && newStage === "Confirmation") {
          setExamReportLive(undefined);
        }
      } catch (e) {}
    },
    [deviceId, connection, getAccessTokenSilently]
  );

  // handle the device subscription
  const handleSubscribeToDevice = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await subscribeToDevice(token, deviceId, connection);

      const data = JSON.parse(response);
      if (data?.state === true) {
        // sus
        connection?.off("DeviceLiveDataUpdated");
        connection.on("DeviceLiveDataUpdated", function (data) {
          const parseableString = data
            .replace(/\\/g, "")
            .replace(/"{/g, "{")
            .replace(/}"/g, "}");

          const deviceLiveData = JSON.parse(parseableString);
          setExamReportLive(deviceLiveData.data);
        });
      }
    } catch (e) {
      console.error("Error while subscribing:", e.message);
    }
  }, [connection, deviceId, getAccessTokenSilently]);

  // handle the device unsubscription
  const handleUnsubscribeFromDevice = useCallback(async () => {
    try {
      const response = await unsubscribeFromDevice(deviceId, connection);
      const data = JSON.parse(response);
      if (data?.state === true) {
        setExamReportLive(undefined);
        connection?.off("DeviceLiveDataUpdated"); // remove the listener
      }
    } catch (e) {
      console.error("Could not unsubscribe from device:", e.message);
    }
  }, [connection, deviceId]);

  const handleUpdateVolumeRequest = useCallback(
    async (volume) => {
      try {
        const token = await getAccessTokenSilently();
        await deviceUpdateVolumeRequest(token, deviceId, volume, connection);
      } catch (e) {
        console.error(e);
      }
    },
    [connection, deviceId, getAccessTokenSilently]
  );

  const handleVolumeChange = (event) => {
    const sliderValue = event.target.value;
    setVolume(sliderValue);
  };

  const handleVolumeSet = () => {
    handleUpdateVolumeRequest(parseFloat(volume / 100).toFixed(2));
  };

  const handleDeviceCalibrateLobbyRequest = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      await deviceCalibrateLobbyRequested(token, deviceId, connection);
    } catch (e) {
      console.error(e);
    }
  }, [connection, deviceId, getAccessTokenSilently]);

  const handleDeviceExamTerminationRequest = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      await deviceExamTerminationRequest(token, deviceId, connection);
    } catch (e) {
      console.error("Could not terminate", e.message);
    }
  }, [connection, deviceId, getAccessTokenSilently]);

  const handleEnableVisualFieldControllerBeepRequest = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      await deviceEnableVisualFIeldControllerBeepRequest(
        token,
        deviceId,
        connection
      );
      setIsBeepEnabled(true);
    } catch (e) {
      console.error(e);
    }
  }, [connection, deviceId, getAccessTokenSilently]);

  const handleDisableVisualFieldControllerBeepRequest =
    useCallback(async () => {
      try {
        const token = await getAccessTokenSilently();
        await deviceDisableVisualFIeldControllerBeepRequest(
          token,
          deviceId,
          connection
        );
        setIsBeepEnabled(false);
      } catch (e) {
        console.error(e);
      }
    }, [connection, deviceId, getAccessTokenSilently]);

  // Stop WebRTC connection
  const stopWebRTC = useCallback(() => {
    // console.log("WebRTC connection stopped");

    // Close the data channel
    if (dataChannel) {
      dataChannel.close();
      // dataChannel = null;
      setDataChannel(null);
    }

    // Close the peer connection
    if (peerConnection) {
      peerConnection.close();
      // peerConnection = null;
      setPeerConnection(null);
    }

    // Clear display
    setImageSrc(blackout);
    // var img = document.getElementById('DisplayImg');
    // img.src = "";
  }, [dataChannel, peerConnection, setDataChannel, setPeerConnection]);

  const handleCloseModal = useCallback(
    (event, reason) => {
      const isAlternativeQuit =
        reason === "escapeKeyDown" || reason === "backdropClick";

      // not performing the on close when not quitting through the quit button when on vr mode
      if (isAlternativeQuit) return;

      if (peerConnection) stopWebRTC();
      onCloseTasks();
    },
    [onCloseTasks, stopWebRTC, peerConnection]
  );

  const handleNavigate = useCallback(
    (event, reason) => {
      handleCloseModal(event, reason);
      navigate(`/patients/${latestExam?.patient?.id}/exam/${latestExam?.id}`);
    },
    [handleCloseModal, latestExam?.id, latestExam?.patient?.id, navigate]
  );

  // Ensure the request to update this exam info is at the first time.
  useEffect(() => {
    if (open && !examStatus?.device) setShouldFetchExam(true);
  }, [examStatus?.device, open]);

  useEffect(() => {
    if (open)
      setVolume(
        examStatus?.device?.deviceStatus?.volume
          ? Math.round(examStatus?.device?.deviceStatus?.volume * 100)
          : 0
      );
  }, [examStatus?.device?.deviceStatus, open]);

  // When the exam is completed or not started.
  // This part is to retrieve the latest data of the exam
  useEffect(() => {
    if (!open || examStatus?.device || !shouldFetchExam) return;
    const controller = new AbortController();
    const getExam = async () => {
      try {
        const token = await getAccessTokenSilently();
        const newExam = await getExamById(
          token,
          clinicId,
          exam?.patient?.id,
          exam?.id,
          controller.signal
        );
        setLatestExam(newExam);
      } catch (e) {
        // error
      } finally {
        setShouldFetchExam(false);
      }
    };
    getExam();
    return () => {
      controller.abort();
    };
  }, [
    clinicId,
    exam?.id,
    exam?.patient?.id,
    examStatus?.device,
    getAccessTokenSilently,
    open,
    shouldFetchExam,
  ]);

  // When the exam is completed.
  useEffect(() => {
    if (!open || examStatus?.device || !latestExam) return;
    const examSectionsKeys = Object.keys(latestExam).filter((key) =>
      key.includes("Sections")
    );
    const currentExamSectionsKey = examSectionsKeys?.filter(
      (key) => latestExam[key].length > 0
    );
    let completed = 1;
    if (!latestExam[currentExamSectionsKey]) return;
    latestExam[currentExamSectionsKey].forEach((section) => {
      if (section?.completionDate) completed *= 1;
      else completed *= 0;
    });
    if (completed) setShouldDisplayNavigation(true);
    else handleCloseModal();
  }, [examStatus, handleCloseModal, latestExam, open, t]);

  useEffect(() => {
    if (connection && deviceId && exam && !isLoadingSignalR) {
      if (open) {
        handleSubscribeToDevice();
      } else {
        handleUnsubscribeFromDevice();
      }
    }
    return () => {
      connection?.off("DeviceLiveDataUpdated");
    };
  }, [
    connection,
    open,
    deviceId,
    exam,
    getAccessTokenSilently,
    isAuthenticated,
    handleSubscribeToDevice,
    handleUnsubscribeFromDevice,
    isLoadingSignalR,
  ]);

  useEffect(() => {
    const controller = new AbortController();
    if (!isLoadingSignalR) {
      refetchDevices(controller.signal);
      if (open) {
        refetchExams(controller.signal);
      }
    }
    return () => {
      controller.abort();
    };
  }, [isLoadingSignalR, open, refetchDevices, refetchExams]);

  useEffect(() => {
    if (!open) return;
    setIsNotStarted(examStatus?.status === t("word_not_started"));
    setIsPaused(examStatus?.status === t("word_pause"));
    setIsConfirmation(examStatus?.status === t("word_confirmation"));
    setIsRightCompleted(
      examStatus?.status === t("word_right_eye_completed") ||
        examStatus?.status === t("word_right_eye_terminated")
    );
    setIsTerminated(examStatus?.status === t("word_terminated"));
  }, [examStatus?.status, t, open]);

  const elapsedTime = useMemo(() => {
    if (examReportLive?.timing?.elapsed)
      return getColorVisionTime(examReportLive.timing.elapsed);
    return "00:00";
  }, [examReportLive?.timing?.elapsed]);

  useEffect(() => {
    setPercentage(
      Math.floor(
        examReportLive?.stats?.progress
          ? examReportLive.stats.progress * 100
          : examReportLive?.Progress
            ? examReportLive?.Progress * 100
            : 0
      )
    );
  }, [
    examReportLive,
    examReportLive?.stats?.progress,
    examReportLive?.Progress,
  ]);

  useEffect(() => {
    if (!isVrMode && peerConnection) stopWebRTC();
  }, [isVrMode, peerConnection, stopWebRTC]);

  const handleVRModeSwitch = useCallback(async () => {
    // when leaving vr mode
    if (isVrMode) {
      // setHasSubscribedToExam(false);
      // when disabling the VR Mode with a unityInstance up (the unityInstance should be up, but we check for security reasons), we call handleUnsubscribeFromExamProgress
      // if (unityInstance) {
      //   // unsubscribe from exam
      //   await handleUnsubscribeFromExamProgress();
      // }
    }
    setIsVrMode((prev) => !prev);

    // setIsVrMode((prev) => !prev);
  }, [isVrMode]);

  useEffect(() => {
    if (isConfirmation || isNotStarted || isRightCompleted || isTerminated) {
      setPercentage(0);
      setExamReportLive(undefined);
    }
  }, [isConfirmation, isRightCompleted, isNotStarted, isTerminated]);

  const leftExamCanStart = useMemo(() => {
    const updatedExam = examStatus?.device?.associatedExam?.exam;
    if (!updatedExam) return false;
    const examSectionsKeys = Object.keys(updateExam).filter((key) =>
      key.includes("Sections")
    );
    const currentExamSectionsKey = examSectionsKeys?.filter(
      (key) => updatedExam[key].length > 0
    );

    const sections = updatedExam?.[currentExamSectionsKey?.[0]];
    return (
      sections?.length > 1 &&
      sections[0].completionDate === null &&
      examStatus?.device?.deviceStatus?.stage?.stageType === "Congratulations"
    );
  }, [examStatus]);

  // the eye that the live update modal refers to (Left or Right)
  // the logic is:
  // - if there is only one section, then simply get the eye that it is related to
  // - if there are two section:
  //    - if right eye is not completed, then consider the right eye as the current one
  //    - if right eye is completed, then consider the left eye as the current one
  //    - to check if the section is completed, we should check if the completionDate field within the section, is null
  const eye = useMemo(() => {
    const updatedExam = examStatus?.device?.associatedExam?.exam;
    if (!updatedExam) return "";
    const examSectionsKeys = Object.keys(updatedExam).filter((key) =>
      key.includes("Sections")
    );
    const currentExamSectionsKey = examSectionsKeys?.filter(
      (key) => updatedExam[key].length > 0
    );
    if (!updatedExam?.[currentExamSectionsKey?.[0]]?.length) return "";

    const rightSection = updatedExam[currentExamSectionsKey[0]].find(
      (section) => section.eye === eyeRight
    );
    const leftSection = updatedExam[currentExamSectionsKey[0]].find(
      (section) => section.eye === eyeLeft
    );

    if (!rightSection && !leftSection) return "";

    return !rightSection ||
      (leftSection?.order === 0 && !leftSection?.completionDate) ||
      (rightSection?.order === 0 && rightSection?.completionDate)
      ? eyeLeft
      : eyeRight;
  }, [examStatus, eyeRight, eyeLeft]);

  // the data for the number chart (for visual field), or esterman chart (for esterman)
  const numberEstermanChartData = useMemo(() => {
    // disconsider if chart is not visual field/esterman
    if (!(isVisualField || isEsterman)) return null;
    const spots = getChartSpots(examReportLive);
    return spots;
  }, [examReportLive, isVisualField, isEsterman]);

  // the data for the gaze chart
  const gazeChartData = useMemo(() => {
    // disconsider if chart is not visual field/esterman
    if (!(isVisualField || isEsterman)) return null;
    const { gazeSpots, eyeTrackingError } = getGazeChartData(examReportLive);

    setshouldDisplayEyeTrackingError(
      isVisualField &&
        !isRightCompleted &&
        examReportLive &&
        gazeSpots?.length >= 10 &&
        eyeTrackingError
    );
    return gazeSpots;
  }, [isVisualField, isEsterman, examReportLive, isRightCompleted]);

  // useEffect(() => {

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isNotStarted, isPaused, isRightCompleted, gazeChartData]);

  // the possible steps
  // remove the calibration stage option, when the exam has "calibration" as false
  // remove the tutorial stage option, when the exam has "requiresTutorial" as false
  const possibleSteps = useMemo(
    () =>
      Object.values(DeviceStagesTimeline)
        .filter(
          (stage) =>
            stage !== DeviceStagesTimeline.Calibration ||
            (stage === DeviceStagesTimeline.Calibration && exam?.calibration)
        )
        .filter(
          (stage) =>
            stage !== DeviceStagesTimeline.Tutorial ||
            (stage === DeviceStagesTimeline.Tutorial && exam?.requiresTutorial)
        )
        // Todo - Completed -> Terminated
        .filter((stage) => stage !== DeviceStagesTimeline.Terminated),
    [exam]
  );

  // the active step
  const activeStep = useMemo(
    () => getActiveStep(exam, possibleSteps, examStatus),
    [exam, possibleSteps, examStatus]
  );

  const getExamTypeName = useCallback(() => {
    if (exam?.visualFieldSections?.length)
      return t("exams_table_expand_visual_field");
    if (exam?.colorVisionSections?.length) return t("color_vision_title");
    if (exam?.contrastSensitivitySections?.length)
      return t("contrast_sensitivity_title");
    if (exam?.visualAcuitySections?.length)
      return `${t("visual_acuity_title")} / ${exam?.visualAcuitySections?.[0]?.position}`;
    return "";
  }, [
    exam?.colorVisionSections?.length,
    exam?.contrastSensitivitySections?.length,
    exam?.visualAcuitySections,
    exam?.visualFieldSections?.length,
    t,
  ]);
  // method to show the exam type and grid
  const getExamTypeDisplay = useCallback(() => {
    const type = getExamType(exam);
    const visualField = getVisualField(exam);

    if (!type) return "";

    // the esterman exam is a visual field exam, but we don't want the grid to be shown
    if (exam?.type === "VisualField" && visualField) {
      return `${getExamTypeName()} / ${getVisualField(exam)} ${type}`;
    } else if (type === TYPE_VISUAL_ACUITY) {
      return `${getExamTypeName()} / ${t("word_tumbling")}`;
    } else {
      return `${getExamTypeName()} / ${type}`;
    }
  }, [exam, TYPE_VISUAL_ACUITY, getExamTypeName, t]);

  // method to display which eye the exam is related to (left/right, in case it's a visual field exam)
  const getEyeSideDisplay = useCallback(() => {
    return eye && typeof eye === "string"
      ? `${t(`word_${eye.toLowerCase()}`)} ${t("word_eye")} ${t("word_exam")}`
      : "";
  }, [eye, t]);

  // check what exam description to display, based on the responses from getExamTypeDisplay and getEyeSideDisplay
  const getExamDescriptionDisplay = useCallback(() => {
    const examDisplay = getExamTypeDisplay();
    const eyeSideDisplay = getEyeSideDisplay();

    if (!examDisplay && !eyeSideDisplay) return "";
    if (examDisplay && eyeSideDisplay)
      return `${examDisplay} / ${eyeSideDisplay}`;

    if (examDisplay) return examDisplay;
    if (eyeSideDisplay) return eyeSideDisplay;

    return "";
  }, [getExamTypeDisplay, getEyeSideDisplay]);

  const UpperSection = useMemo(
    // (( exam, examStatus, activeStep, possibleSteps) =>
    () => (
      <Box display="flex" flexDirection="column" gap={0.5}>
        <Typography variant="h6" fontWeight="bold">
          {getExamDescriptionDisplay()}
        </Typography>
        {!isVisualField && !isEsterman && (
          <Typography>
            <span style={{ fontWeight: "bold" }}>Elapsed time </span>
            {elapsedTime}
          </Typography>
        )}
        <Stepper
          activeColor="#E2772E"
          activeTitleColor="#E2772E"
          completeColor="#202338"
          completeTitleColor="#202338"
          completeBarColor="#202338"
          circleFontColor="rgba(0,0,0,0)"
          steps={possibleSteps.map((stage) => {
            // if the exam is at the Exam stage, we also display the status (as it may be In Progress or Paused)
            const isExamOrTutorialStage =
              (stage === DeviceStagesTimeline.Exam ||
                stage === DeviceStagesTimeline.Tutorial) &&
              activeStep.value === stage;
            return {
              title: getDeviceStagesTimelineText(
                stage,
                isExamOrTutorialStage,
                t("word_patient_login"),
                t("word_confirmation"),
                t("word_tutorial"),
                t("word_exam"),
                examStatus?.extraWord ?? examStatus?.status,
                t("word_completed"),
                t("word_calibration")
              ),
            };
          })}
          activeStep={activeStep.index}
        />
        <Box display="flex" gap={1} mt={4}>
          <Typography
            noWrap
            component="div"
            variant="body2"
            sx={{ fontWeight: "bold", flex: 1 }}
          >
            {`${t("word_progress")}:`}
          </Typography>
          <Grid
            item
            sx={{ alignItem: "center", flex: 3.5 }}
            display="flex"
            alignItems="center"
          >
            <ProgressBar value={percentage} />
            <Typography
              ml={0.5}
              sx={{ fontWeight: "bold", flex: 1 }}
              variant="body2"
            >
              {percentage ? percentage : 0}%
            </Typography>
          </Grid>
        </Box>
      </Box>
    ),
    [
      activeStep.index,
      activeStep.value,
      elapsedTime,
      examStatus?.extraWord,
      examStatus?.status,
      getExamDescriptionDisplay,
      isEsterman,
      isVisualField,
      percentage,
      possibleSteps,
      t,
    ]
  );

  const HidingChartMessage = useMemo(
    () => (
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        height="324px"
      >
        <Typography>{t("tutorial_hiding_contents_alert")}</Typography>
      </Box>
    ),
    [t]
  );

  // return in case no exam is currently selected
  if (!exam) return null;

  const disableRestartTermination =
    (activeStep.value !== DeviceStagesTimeline.Exam &&
      activeStep.value !== DeviceStagesTimeline.Tutorial) ||
    isLoadingSignalR;

  const inTutorial = activeStep.value === DeviceStagesTimeline.Tutorial;
  const inCalibration = activeStep.value === DeviceStagesTimeline.Calibration;
  const bothControllersDisconnected =
    examStatus?.device?.deviceStatus?.controllerBatteryL === -1 &&
    examStatus?.device?.deviceStatus?.controllerBatteryR === -1;
  const hmdInProximity = examStatus?.device?.deviceStatus?.hmdInProximity;

  const MiddleSection = isVrMode ? (
    <Box pt={2}>
      <WebRtcComponent
        peerConnection={peerConnection}
        setPeerConnection={setPeerConnection}
        dataChannel={dataChannel}
        setDataChannel={setDataChannel}
        connection={connection}
        deviceId={deviceId}
        deviceStage={examStatus?.device?.deviceStatus?.stage}
        hmdInProximity={examStatus?.device?.deviceStatus?.hmdInProximity}
        imageSrc={imageSrc}
        setImageSrc={setImageSrc}
        stopWebRTC={stopWebRTC}
      />
    </Box>
  ) : (
    <>
      {isVisualField || isEsterman ? (
        <Box
          display="flex"
          flexDirection="column"
          mt={{ xs: 1 }}
          px={3}
          py={2}
          gap={4}
          sx={{ border: "1px dashed rgba(0,0,0,0.2)", borderRadius: 2 }}
        >
          <Box display="flex" flexDirection="column">
            <Typography
              component="div"
              variant="body1"
              sx={{ fontWeight: "bold" }}
            >
              {t("eye_tracking")}
            </Typography>
            {!isConfirmation && !isNotStarted && (
              <GazeGraph data={gazeChartData || []} />
            )}
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography
              component="div"
              variant="body1"
              sx={{ fontWeight: "bold" }}
            >
              {t("word_grid")}
            </Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              pr={2}
            >
              {(isVisualField || isEsterman) && (
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={2}
                  mt={2}
                  justifyContent="center"
                >
                  <Tooltip title={t("false_positive")} placement="right">
                    <Box display="flex" gap={1}>
                      <Typography
                        component="div"
                        variant="h6"
                        sx={{ fontWeight: "bold" }}
                        lineHeight={1.2}
                      >
                        FP
                      </Typography>
                      <Typography component="div" variant="body1">
                        {falsePositivePercentage}
                      </Typography>
                    </Box>
                  </Tooltip>
                  <Tooltip title={t("false_negative")} placement="right">
                    <Box display="flex" gap={1}>
                      <Typography
                        component="div"
                        variant="h6"
                        sx={{ fontWeight: "bold" }}
                        lineHeight={1.2}
                      >
                        FN
                      </Typography>
                      <Typography component="div" variant="body1">
                        {falseNegative}
                      </Typography>
                    </Box>
                  </Tooltip>
                  <Tooltip title={t("fixation_loss")} placement="right">
                    <Box display="flex" gap={1}>
                      <Typography
                        component="div"
                        variant="h6"
                        sx={{ fontWeight: "bold" }}
                        lineHeight={1.2}
                      >
                        FL
                      </Typography>
                      <Typography component="div" variant="body1">
                        {fixationLossPercentage}
                      </Typography>
                    </Box>
                  </Tooltip>
                </Box>
              )}
              {/* For visual field (that are not single threshold), we show the number graph */}
              {(inTutorial || inCalibration) && HidingChartMessage}
              {isVisualField &&
                !isSingleThreshold &&
                examReportLive &&
                exam &&
                !isConfirmation &&
                !isNotStarted &&
                !inTutorial &&
                !inCalibration && (
                  <NumberGraph
                    pressedPoints={numberEstermanChartData || []}
                    gridType={exam?.visualFieldSections[0].gridType}
                    shouldDisplayTriangle={
                      exam?.visualFieldSections[0].gridType !== "G_10_2"
                    }
                    isNotRlFast={
                      exam?.visualFieldSections[0].algorithm !== "SCREEN_FAST"
                    }
                    isLiveUpdate={true}
                    eyeLiveUpdate={eye}
                  />
                )}
              {/* For visual field (that are single threshold), we show the single threshold graph */}
              {isVisualField &&
                isSingleThreshold &&
                examReportLive &&
                exam &&
                !isConfirmation &&
                !isNotStarted &&
                !inTutorial &&
                !inCalibration && (
                  <SingleThresholdGraph
                    pressedPoints={numberEstermanChartData || []}
                    eye={eye}
                    gridType={exam?.visualFieldSections[0].gridType}
                    tickNum={6}
                  />
                )}
              {/* For esterman, we show the esterman graph */}
              {isEsterman &&
                examReportLive &&
                exam &&
                !isConfirmation &&
                !isNotStarted &&
                !inTutorial &&
                !inCalibration && (
                  <EstermanGraph
                    pressedPoints={numberEstermanChartData || []}
                    eye={eye}
                    gridType={exam?.visualFieldSections[0].gridType}
                    isLiveUpdate={true}
                  />
                )}
              {!examReportLive && isPaused && `${t("currently_paused")}.`}
              {!examReportLive &&
                (!isPaused || isConfirmation) &&
                !inCalibration &&
                `${t("no_data_available")}.`}
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          {inTutorial || inCalibration ? (
            HidingChartMessage
          ) : (
            <>
              {exam?.type === typeColorVision &&
                examReportLive &&
                !inTutorial && (
                  <ColorVisionGraph data={examReportLive} isLiveUpdate={true} />
                )}
              <Grid container pt={1}>
                {(isPelirobson ||
                  isTumbling ||
                  isVisualAcuity ||
                  isLandoltC ||
                  isSnellen) &&
                  examReportLive && (
                    <Grid item xs={12} pb={1}>
                      <ChartLegendExceptVisualField isLiveUpdate={true} />
                    </Grid>
                  )}
                {/* pellirobson */}
                {isPelirobson && examReportLive && !inTutorial && (
                  <PelliRobson examData={examReportLive} />
                )}
                {/* Tumbling */}
                {isTumbling && examReportLive && !inTutorial && (
                  <TumblingE examData={examReportLive} />
                )}
                {/* Visual Acuity */}
                {isVisualAcuity && examReportLive && (
                  <VisualAcuityGraph
                    examData={examReportLive}
                    isLiveUpdate={true}
                  />
                )}

                {isLandoltC && examReportLive && (
                  <VisualAcuityGraph
                    examData={examReportLive}
                    isLandoltC={isLandoltC}
                    // isLiveUpdate={true}
                  />
                )}
                {isSnellen && examReportLive && (
                  <VisualAcuityGraph
                    examData={examReportLive}
                    isSnellen={isSnellen}
                    isLiveUpdate={true}
                  />
                )}
              </Grid>
            </>
          )}
        </>
      )}
    </>
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        fullScreen={fullScreen}
        PaperProps={{
          sx: {
            ...ModalPaperProps,
            maxWidth: { xs: "100%", md: "100%" },
            width: {
              xs: "100%",
              md: isEsterman || isSingleThreshold ? "1080px" : "760px",
            },
            height: { xs: "100%", md: "95%" },
          },
        }}
        maxWidth={false}
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{ padding: 1 }}
                display="flex"
                justifyContent="space-between"
              >
                {isLoadingSignalR && (
                  <Box
                    position="fixed"
                    display="flex"
                    flexDirection="row"
                    top={10}
                    right={10}
                    zIndex={100}
                  >
                    <Loader containerHeight="5px" />
                    <Typography
                      variant="h4"
                      fontWeight="bold"
                      color="white"
                      pl={1}
                      fontSize={{ xs: "2.1rem", sm: "1.9rem" }}
                    >
                      {t("live_update_reconnecting")}
                    </Typography>
                  </Box>
                )}
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  gap={2}
                >
                  <FontAwesomeIcon
                    icon={faCircle}
                    beat
                    size="sm"
                    color="green"
                  />
                  <Typography
                    style={{ fontWeight: "bold" }}
                    variant="h5"
                    component="h2"
                  >
                    {patientName}
                  </Typography>
                  <Box display={"flex"} ml={1} gap={1}>
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <VolumeUp />
                      <input
                        type="range"
                        min="0"
                        max="100"
                        value={volume}
                        onChange={handleVolumeChange}
                        onMouseUp={handleVolumeSet}
                        onTouchEnd={handleVolumeSet} // mobile
                        style={{ marginRight: "10px" }}
                      />

                      <span>{volume}</span>
                    </Box>
                    {(isVisualField || isEsterman) && (
                      <Box
                        style={{
                          position: "relative",
                          display: "inline-block",
                          marginLeft: "12px",
                        }}
                      >
                        <Button
                          disabled={!isOnline}
                          sx={{
                            opacity: !isOnline ? 0.3 : 1,
                          }}
                          onClick={
                            isBeepEnabled
                              ? handleDisableVisualFieldControllerBeepRequest
                              : handleEnableVisualFieldControllerBeepRequest
                          }
                        >
                          <Box
                            style={{
                              position: "relative",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              width: "40px",
                              height: "40px",
                            }}
                          >
                            <img
                              src={
                                isBeepEnabled
                                  ? controllerAudioWave
                                  : controllerAudioWaveMuted
                              }
                              alt="controller-with-sound-effect"
                              width={"35%"}
                              style={{
                                marginTop: "-8px",
                              }}
                            />
                            <img
                              src={leftControllerBlack}
                              alt="controller-with-sound-effect"
                              height={"100%"}
                            />
                          </Box>
                        </Button>
                      </Box>
                    )}
                    <Button
                      onClick={handleDeviceCalibrateLobbyRequest}
                      disabled={isNotStarted || !isOnline}
                      sx={{ opacity: isNotStarted || !isOnline ? 0.3 : 1 }}
                    >
                      <img src={calibration} alt="calibration" width="40px" />
                    </Button>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  gap={4}
                >
                  <IconButton
                    aria-label="close-modal"
                    size="medium"
                    sx={{
                      color: "#202338",
                      mx: 0,
                    }}
                    // disabled={!shouldEnableUnloading()}
                    onClick={handleCloseModal}
                  >
                    <FontAwesomeIcon icon={faClose} />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column" gap={2} padding={2}>
                {UpperSection}
                {MiddleSection}
              </Box>
              <Grid item xs={12} px={2}>
                {examStatus?.device &&
                  (isOnline ? (
                    <>
                      {!hmdInProximity && (
                        <Alert
                          variant="outlined"
                          severity="error"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mt: 1,
                            justifyContent: "flex-start",
                          }}
                          aria-label="hmd-error-alert"
                        >
                          <Typography>{t("live_update_hmd_error")}</Typography>
                        </Alert>
                      )}
                      {bothControllersDisconnected && (
                        <Alert
                          variant="outlined"
                          severity="error"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mt: 1,
                            justifyContent: "flex-start",
                          }}
                          aria-label="no-controllers-error-alert"
                        >
                          <Typography>
                            {t("live_update_no_controller_error")}
                          </Typography>
                        </Alert>
                      )}
                    </>
                  ) : (
                    <Alert
                      variant="outlined"
                      severity="error"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mt: 1,
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography>{t("live_update_offline")}</Typography>
                    </Alert>
                  ))}
              </Grid>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                padding={3}
                mb={3}
                gap={7}
                marginBottom={0}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  gap={1}
                >
                  <Tooltip
                    title={
                      isPaused ||
                      (bothControllersDisconnected &&
                        (isConfirmation || leftExamCanStart))
                        ? t("play_tooltip")
                        : t("pause_tooltip")
                    }
                    disableHoverListener={
                      (activeStep.value !== DeviceStagesTimeline.Exam &&
                        activeStep.value !==
                          DeviceStagesTimeline.Confirmation &&
                        !inTutorial &&
                        !leftExamCanStart) ||
                      isLoadingSignalR ||
                      bothControllersDisconnected ||
                      !hmdInProximity
                    }
                  >
                    <span>
                      <IconButton
                        aria-label="pause and resume button"
                        size="medium"
                        sx={{
                          color: "#202338",
                          backgroundColor: "rgba(0,0,0,0.3)",
                          "&:hover": {
                            color: "white",
                            backgroundColor: "#202338",
                            borderColor: "#202338",
                          },
                          width: 52,
                          height: 52,
                        }}
                        disabled={
                          (activeStep.value !== DeviceStagesTimeline.Exam &&
                            activeStep.value !==
                              DeviceStagesTimeline.Confirmation &&
                            !inTutorial &&
                            !leftExamCanStart) ||
                          isLoadingSignalR ||
                          ((bothControllersDisconnected || !hmdInProximity) &&
                            (isPaused || isConfirmation || leftExamCanStart))
                        }
                        onClick={() =>
                          handleUpdateDeviceStage(
                            isPaused && inTutorial
                              ? "Tutorial"
                              : isPaused || isConfirmation || leftExamCanStart
                                ? "Exam"
                                : "Pause"
                          )
                        }
                      >
                        <FontAwesomeIcon
                          icon={
                            isPaused || isConfirmation || leftExamCanStart
                              ? faPlay
                              : faPause
                          }
                        />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Typography
                    noWrap
                    component="div"
                    variant="body2"
                    sx={{ fontWeight: "bold" }}
                  >
                    {isPaused || isConfirmation || leftExamCanStart
                      ? t("word_play")
                      : t("word_pause")}
                  </Typography>
                </Box>
                {/* Restart button */}
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  gap={1}
                >
                  <Tooltip
                    title={t("restart_tooltip")}
                    disableHoverListener={disableRestartTermination}
                  >
                    <span>
                      <IconButton
                        aria-label="restart button"
                        size="small"
                        sx={{
                          color: "#3452eb",
                          backgroundColor: "#3452eb20",
                          "&:hover": {
                            color: "white",
                            backgroundColor: "#3452eb",
                            borderColor: "#3452eb",
                          },
                          width: 52,
                          height: 52,
                        }}
                        disabled={disableRestartTermination}
                        // Restart the exam
                        onClick={() => handleUpdateDeviceStage("Confirmation")}
                      >
                        <FontAwesomeIcon icon={faArrowRotateRight} size="lg" />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Typography
                    noWrap
                    component="div"
                    variant="body2"
                    sx={{ fontWeight: "bold", color: "#3452eb" }}
                  >
                    {t("word_restart")}
                  </Typography>
                </Box>
                {/* Terminate button */}
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  gap={1}
                >
                  <Tooltip
                    title={t("terminate_tooltip")}
                    disableHoverListener={disableRestartTermination}
                  >
                    <span>
                      <IconButton
                        aria-label="terminate button"
                        size="small"
                        sx={{
                          color: "#c62828",
                          borderColor: "#c62828",
                          backgroundColor: "#c6282820",
                          "&:hover": {
                            color: "white",
                            backgroundColor: "#c62828",
                            borderColor: "#c62828",
                          },
                          width: 52,
                          height: 52,
                        }}
                        disabled={disableRestartTermination}
                        // Terminate the exam
                        onClick={() => handleDeviceExamTerminationRequest()}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Typography
                    noWrap
                    component="div"
                    variant="body2"
                    sx={{ fontWeight: "bold", color: "#c62828" }}
                  >
                    {t("word_terminate")}
                  </Typography>
                </Box>
                {/* Skip tutorial */}
                {inTutorial && !isLoadingSignalR && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    gap={1}
                  >
                    <Tooltip
                      title={t("skip_tutorial_tooltip")}
                      disableHoverListener={disableRestartTermination}
                    >
                      <span>
                        <IconButton
                          aria-label="skip tutorial button"
                          size="small"
                          sx={{
                            color: "#038707",
                            borderColor: "#038707",
                            backgroundColor: "#03870720",
                            "&:hover": {
                              color: "white",
                              backgroundColor: "#038707",
                              borderColor: "#038707",
                            },
                            width: 52,
                            height: 52,
                          }}
                          // Terminate the exam
                          onClick={() => handleUpdateDeviceStage("Exam")}
                        >
                          <FontAwesomeIcon icon={faForward} />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Typography
                      noWrap
                      component="div"
                      variant="body2"
                      sx={{ fontWeight: "bold", color: "#038707" }}
                    >
                      {t("word_skip")}
                    </Typography>
                  </Box>
                )}
                {/* Commenting this feature for now, as we still need to do some improvements to it */}
                {clinicLicensing?.canVideoStream && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    gap={1}
                  >
                    <Box>
                      <IconButton
                        aria-label="terminate button"
                        size="small"
                        sx={{
                          color: "#E2772E",
                          borderColor: "#E2772E",
                          backgroundColor: "#E2772E40",
                          "&:hover": {
                            color: "white",
                            backgroundColor: "#E2772E",
                            borderColor: "#E2772E",
                          },
                          width: 52,
                          height: 52,
                        }}
                        // switches the view mode
                        // disabled={!shouldEnableUnloading()}
                        onClick={() => handleVRModeSwitch()}
                      >
                        <FontAwesomeIcon
                          icon={!isVrMode ? faVrCardboard : faListCheck}
                        />
                      </IconButton>
                    </Box>
                    <Typography
                      noWrap
                      component="div"
                      variant="body2"
                      sx={{ fontWeight: "bold", color: "#E2772E" }}
                    >
                      {!isVrMode ? "VR Mode" : "Test Mode"}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      {shouldDisplayNavigation && (
        <LiveUpdateNavigationModal
          open={shouldDisplayNavigation}
          onClose={handleCloseModal}
          onConfirm={handleNavigate}
          onCancel={handleCloseModal}
          loading={false}
        />
      )}
    </>
  );
};

export default LiveUpdateModal;
