import { createContext, useState } from "react";

import useLocalStorage from "../hooks/useLocalStorage";

const ClinicContext = createContext({
  clinicId: "",
  clinicName: "",
  clinicLang: "",
  clinicLicensing: {
    canVideoStream: false,
    canUseDicomReports: false,
    canUsePngReports: false,
    canDisplayTotalAndPatternDeviationNumericalCharts: false,
  },
  clinicSettings: {
    id: "",
    clinicDefaultLanguage: "",
    patientDefaultLanguage: "",
    colorVisionDefaultTimeLimit: 0,
    visualFieldDefaultAlgorithm: "",
    visualFieldDefaultGridType: "",
    visualFieldDefaultEye: "",
    visualFieldDefaultFixationPointShapeType: "",
    visualFieldDefaultStimulusSizeType: "",
    visualFieldDefaultRequiresTutorial: false,
    visualFieldDefaultAutoPause: false,
    visualFieldDefaultEncouragement: true,
    visualFieldDefaultCalibration: false,
    visualFieldDefaultFixationLossMethodType: "",
    lastModifiedDate: "",
    lastModifiedBy: "",
    examDefaultRightEyeFirst: false,
  },
  patientDefaultLang: "",
  allClinicInfo: [],
  setClinicId: () => {},
  setClinicName: () => {},
  setClinicLang: () => {},
  setClinicLicensing: () => {},
  setClinicSettings: () => {},
  setPatientDefaultLang: () => {},
  setAllClinicInfo: () => {},
});

const ClinicProvider = ({ children }) => {
  const [clinicId, setClinicId] = useLocalStorage("clinicId", "");
  const [clinicName, setClinicName] = useLocalStorage("clinicName", "");
  const [allClinicInfo, setAllClinicInfo] = useLocalStorage("allClinics", []);
  const [selectedPatient, setSelectedPatient] = useLocalStorage(
    "selectedPatient",
    []
  );
  const [clinicLang, setClinicLang] = useState(null);
  const [clinicLicensing, setClinicLicensing] = useState({});
  const [clinicSettings, setClinicSettings] = useState({});
  const [patientDefaultLang, setPatientDefaultLang] = useState(null);

  return (
    <ClinicContext.Provider
      value={{
        clinicId,
        clinicName,
        clinicLang,
        clinicLicensing,
        clinicSettings,
        patientDefaultLang,
        allClinicInfo,
        setClinicId,
        setClinicName,
        setAllClinicInfo,
        selectedPatient,
        setSelectedPatient,
        setClinicLang,
        setClinicLicensing,
        setClinicSettings,
        setPatientDefaultLang,
      }}
    >
      {children}
    </ClinicContext.Provider>
  );
};

export { ClinicContext, ClinicProvider };
